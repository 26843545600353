import { Stack, Typography } from '@mui/material';
import React from 'react';

export const WidgetHeader = ({
    mode,
    widgetData,
    sortedData,
    count,
    widgetTypeId,
    headerAction,
}: {
    mode: string | undefined;
    widgetData: any;
    sortedData?: any;
    count?: number;
    headerAction?: any;
    widgetFilter?: any;
    widgetTypeId?: string;
}): JSX.Element => {
    const { widgetName, isTotalCountVisible } = widgetData || {};
    const secondaryLength = sortedData?.secondary?.length || widgetData?.secondary?.length || 0;
    const displayCount = count ? count : secondaryLength;

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            position="relative"
            justifyContent={'space-between'}
            ml={1}
            sx={
                mode?.includes('edit')
                    ? {
                          position: 'absolute',
                          top: '0px',
                          height: '48px',
                          left: widgetTypeId === 'command_bar' && mode === 'pinned-edit' ? '16px' : '50px',
                          width: 'calc(100% - 106px)',
                      }
                    : { height: '48px', padding: '16px', width: 'calc(100% - 8px)' }
            }
            width={'calc(100% - 106px)'}
        >
            <Typography
                variant="subtitle2"
                color="primary"
                fontWeight={'600'}
                fontSize={'14px'}
                position={'absolute'}
                width={'100%'}
            >
                {widgetName}
                {isTotalCountVisible && (Boolean(secondaryLength) || Boolean(displayCount)) && (
                    <span>{`( ${displayCount} )`}</span>
                )}
            </Typography>
            {headerAction}
        </Stack>
    );
};
