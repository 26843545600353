import React from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setAddWidgetModal } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { Box, Button, Stack } from '@mui/material';
import { Header, useConfigureWidgets } from './';
import { EditDashboardDetails, GridLayout } from '../Common';
import DashboardCustomize from '@mui/icons-material/DashboardCustomize';
import Edit from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { CustomMenu } from 'components/CustomMenu';
import { CustomTransComponent } from 'components';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';

export const ConfigureDashboard = (): JSX.Element => {
    const theme: any = useTheme();
    const { dashboardId } = useParams();
    const dispatch = useAppDispatch();

    const { state } = useLocation();
    const permissions = useTypedSelector(selectCurrentPermission);
    const editDetailsModalRef = React.useRef<any>(null);

    const widgetList = useTypedSelector((rootState) => rootState.dashboard.widgetList);

    const { hasPermission } = useRBAC(permissions);
    const canCreateSystemWidgets = hasPermission('create-system-widgets');
    const canCreateUserWidgets = hasPermission('create-user-widgets');

    const canUpdateSystemDashboard = hasPermission('edit-system-dashboards');
    const canUpdateUserDashboard = hasPermission('edit-user-dashboards');

    const getUserData = (): any => {
        const menuGroups = [];

        if (canUpdateSystemDashboard || canUpdateUserDashboard) {
            menuGroups.push({
                title: <CustomTransComponent size={{ width: '140px' }} translationKey={'COMMON:EDIT_DETAILS'} />,
                icon: <Edit />,
                onClick: (): void => {
                    editDetailsModalRef?.current?.handleModalAction(true);
                },
            });
        }

        return {
            id: 'profile-menu',
            menuGroups,
            menuTitle: '',
            menuSubtitle: '',
        };
    };

    const { isLoading } = useConfigureWidgets();

    return (
        <>
            <Header />
            <Box>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={
                        (canCreateSystemWidgets || canCreateUserWidgets) &&
                        (canUpdateSystemDashboard || canUpdateUserDashboard)
                            ? 'space-between'
                            : 'flex-end'
                    }
                    width={'100%'}
                    py={1}
                    px={3}
                    className="bg-white"
                >
                    {(canCreateSystemWidgets || canCreateUserWidgets) && (
                        <Button
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                },
                            }}
                            variant="outlined"
                            startIcon={<DashboardCustomize />}
                            onClick={(): void => {
                                if (widgetList?.length >= 12) {
                                    dispatch(
                                        setMessageContent({
                                            isOpen: true,
                                            message: 'Maximum 12 widgets can be dropped on a dashboard.',
                                        })
                                    );
                                    return;
                                }
                                dispatch(
                                    setAddWidgetModal({
                                        isOpen: true,
                                        mode: 'dashboard',
                                        dashboardId: dashboardId,
                                        assignType: state.assignType,
                                        assignId: state.assignId,
                                    })
                                );
                            }}
                        >
                            <CustomTransComponent translationKey={'WIDGETS:ADD_WIDGET'} />
                        </Button>
                    )}
                    {(canUpdateSystemDashboard || canUpdateUserDashboard) && <CustomMenu menuList={getUserData()} />}
                </Stack>
            </Box>
            <GridLayout isLoading={isLoading} dashboardMode="dashboard" />
            <EditDashboardDetails ref={editDetailsModalRef} />
        </>
    );
};
