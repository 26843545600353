import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { WidgetHeader, WidgetFooter } from 'pages/WidgetManagement/common';
import { Divider, Paper, Stack } from '@mui/material';
import WidgetNotConfigured from 'pages/EditWidget/WIdgetNotConfigured';
import { CustomTransComponent } from 'components';
import TelemetryChart from './TelemetryChart';
import { HeaderAction } from '../aggregated_trends/HeaderAction';

type TelemetryChartProps = {
    type?: 'bar' | 'column';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount?: number;
    secondary: any[];
    widgetName?: string;
    [key: string]: any;
};

const Component = ({
    widgetData,
    mode,
    color,
    widgetFilter,
    downloadCSVHandler,
}: {
    widgetData: TelemetryChartProps;
    mode: string;
    color: string;
    widgetFilter?: any;
    downloadCSVHandler?: any;
}): JSX.Element => {
    const chartWidget = JSON.parse(JSON.stringify(widgetData));
    if (chartWidget?.isDataSortDesc) {
        chartWidget?.secondary?.sort((a: { value: number }, b: { value: number }) => a?.value - b?.value);
    } else {
        chartWidget?.secondary?.reverse();
    }
    const componentRef = React.useRef<HTMLDivElement | null>(null);
    const [chartRect, setChartRect] = React.useState<any>(null);
    const chartRef: any = useRef(null);
    const [option, setOption] = React.useState({
        chart: {
            type: 'bar',
        },
        title: {
            text: null,
        },
        xAxis: {
            title: {
                text: null,
            },
            lineWidth: 0,
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
            tickColor: '#f0f8ff00',
            minPadding: 5,
            tickInterval: 2,
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                format: '{value} KW',
                overflow: 'justify',
            },
            gridLineWidth: 0,
            tickInterval: 2,
            lineWidth: 2,
            tickColor: '#f0f8ff00',
            minPadding: 5, // Adjust this value as needed
            // maxPadding: 1, // Adjust this value as needed
        },
        plotOptions: {
            bar: {
                borderRadius: '20%',
                pointWidth: 30,
                pointPadding: 1,
                groupPadding: 1,
            },
            column: {
                borderRadius: '20%',
                pointWidth: 30,
                pointPadding: 1,
                groupPadding: 1,
            },
            series: {
                useHTML: true,
                dataLabels: {
                    enabled: true,
                    inside: true,
                    rotation: widgetData?.type === 'bar' ? 0 : -90,
                    align: 'left',
                    style: {
                        fontWeight: '100',
                    },
                    ...(widgetData?.type === 'bar' ? { y: 30 } : { x: 30 }),
                    formatter: function (this: any): string {
                        return `
    <span style="font-weight: 300; font-size: 14px;">${this.y}</span> 
    <span style="font-weight: 300; font-size: 14px;">${this.series.tooltipOptions.valueSuffix}</span> 
    <span style="font-weight: 300; margin-left: 16px; font-size: 12px;">${this.series.name}</span>
`;
                    },
                },
                pointPlacement: 'between',
            },
        },
        tooltip: {
            enabled: true,
            formatter: function (this: any) {
                return ` ${this?.series?.name}: ${this?.y} ${this?.series?.tooltipOptions?.valueSuffix} `;
            },
            followPointer: true,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [],
    });

    useEffect(() => {
        Highcharts.setOptions({
            yAxis: {
                lineColor: chartWidget?.type === 'bar' ? '#f0ebeb' : '#ffffff',
            },
        });
    }, [widgetData?.type]);

    const handleResizeComponent = (): void => {
        const hightWidth = componentRef.current
            ?.getElementsByClassName('highcharts-background')?.[0]
            ?.getBoundingClientRect();
        setChartRect(chartWidget?.type === 'bar' ? hightWidth?.width : hightWidth?.height);
    };

    React.useEffect(() => {
        handleResizeComponent();
        window?.addEventListener('resize', handleResizeComponent);
        componentRef?.current?.addEventListener('resize', handleResizeComponent);
    }, []);

    const getSeriesData = (): any[] =>
        chartWidget?.secondary?.slice(0, chartWidget?.maxCount ?? chartWidget?.secondary?.length).map((item: any) => ({
            name: item?.displayName ?? '',
            data: [item?.value ?? 0],
            tooltip: {
                valueSuffix: item?.unit ? ` ${item?.unit}` : '%',
            },
            color: color,
        }));

    const getIntervalValue = (dataValue: any): any => {
        const value: number = dataValue?.reduce?.(
            (max: any, current: any) => (current.value > max.value ? current : max),
            dataValue?.[0]
        )?.value;
        if (value > 10) return Math.ceil(value / 10);
        return value ? (value / 12)?.toFixed?.(2) : undefined;
    };

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        if (chart) {
            const cloneChart: any = JSON.parse(JSON.stringify(option));
            cloneChart['series'] = getSeriesData() ?? [];
            cloneChart['chart'] = {
                type: chartWidget?.type,
            };
            const data: any = JSON?.parse(JSON.stringify(chartWidget?.secondary ?? []));
            cloneChart['yAxis'] = {
                ...(option?.yAxis ?? {}),
                labels: {
                    format: `{value} ${
                        data?.reduce?.((max: any, current: any) => (current.value > max.value ? current : max), data[0])
                            ?.unit ?? '%'
                    }`,
                    overflow: 'justify',
                },
                tickInterval: getIntervalValue(data),
            };
            setOption(cloneChart);
        }
    }, [widgetData?.secondary]);

    const getAbsentDataTitle = (): string | undefined => {
        if (chartWidget?.multiDevice && chartWidget?.assets?.length === 0) {
            return 'Widget Not Configured';
        }
        if ((!chartWidget?.secondary?.length && !chartWidget?.multiDevice) || chartWidget?.assets?.length) {
            return 'No Data Found';
        }
        return undefined;
    };

    return (
        <Paper sx={{ height: '100%' }} ref={componentRef}>
            {(chartWidget.widgetTypeId !== 'consumption_breakdown' || chartWidget.viewTitleBar) &&
                mode !== 'viewAll' && (
                    <WidgetHeader
                        mode={mode}
                        widgetData={chartWidget}
                        widgetFilter={widgetFilter}
                        {...((chartWidget?.viewTimePeriod || chartWidget?.widgetTypeId === 'aggregated_trends') && {
                            headerAction: (
                                <HeaderAction
                                    mode={mode}
                                    widgetData={chartWidget}
                                    widgetFilter={widgetFilter}
                                    downloadCSVHandler={downloadCSVHandler}
                                />
                            ),
                        })}
                    />
                )}
            {mode !== 'edit' && <Divider />}
            {chartWidget.widgetTypeId !== 'consumption_breakdown' ? (
                <TelemetryChart widgetData={chartWidget} mode={mode} chartRect={chartRect} />
            ) : (
                <Stack className="padding-0">
                    {chartWidget?.secondary?.length ? (
                        <HighchartsReact highcharts={Highcharts} options={option} ref={chartRef} />
                    ) : (
                        <WidgetNotConfigured title={getAbsentDataTitle()} />
                    )}
                </Stack>
            )}
            {mode !== 'viewAll' && (
                <WidgetFooter
                    mode={mode}
                    widgetData={{ ...chartWidget, viewAllButton: true }}
                    ids={[]}
                    disabled={!chartWidget?.secondary?.length}
                    {...(chartWidget.widgetTypeId !== 'consumption_breakdown' && {
                        actionBtnName: <CustomTransComponent translationKey={'COMMON:VIEW_FULL_CHART'} />,
                    })}
                />
            )}
        </Paper>
    );
};
export default Component;
