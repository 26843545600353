import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { Theme, useTheme } from '@mui/material/styles';
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutline';
import { CustomModal, CustomTransComponent } from './';
import { ModalActions } from './CustomModal';
import { useOktaAuth } from '@okta/okta-react';
import { useGetOpenRealmSettingsQuery } from '@fiji/common/src/features/realmManagement/realmApi';
import { getRealmNameFromSuffix } from 'utils/helpers';
import { useTypedSelector } from '@fiji/common';

const useStyles = makeStyles((theme: Theme) => ({
    muiDialogpaper: {
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 56px)',
        },
        '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            height: '100%',
            maxHeight: '621px',
            overflowY: 'hidden',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0px',
                maxHeight: 'none',
                width: '100%',
                borderRadius: 0,
                height: '100%',
            },
        },
    },
    avatarBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(5),
    },
    typographyTitle: {
        marginBottom: theme.spacing(2),
        fontSize: '20px',
        color: Colors.black[800],
    },
}));

/**
 * The `NeedHelpScreen` function is a TypeScript React component that renders a modal screen with a
 * chat bubble icon and some content.
 * @param props - - isOpen: a boolean value indicating whether the modal is open or not
 */
export const NeedHelpScreen = (props: { isOpen: boolean; handleCloseModal: () => void }): JSX.Element => {
    const theme: any = useTheme();
    const classes = useStyles(theme);
    const { authState } = useOktaAuth();
    const currentRealmName = useTypedSelector((state: any) => state?.common?.selectedRealm);

    const { data: realmSettings } = useGetOpenRealmSettingsQuery(getRealmNameFromSuffix(currentRealmName), {
        skip:
            !currentRealmName ||
            ['login', 'error', window?.APP_CONFIG?.SUPER_REALM, window.APP_CONFIG.SUPER_ADMIN].includes(
                currentRealmName
            ),
    }) as any;

    const actions: ModalActions[] = [
        {
            key: 'close',
            label: (
                <CustomTransComponent translationKey={'bluiRegistration:CLOSE'} skip={!authState?.isAuthenticated} />
            ),
            close: true,
            variant: 'contained',
            className: 'w-100',
            handleClick: props.handleCloseModal,
        },
    ];
    return (
        <>
            <CustomModal
                isOpen={props.isOpen}
                noDividers
                customHeader={
                    <Box className={classes.avatarBox}>
                        <Avatar
                            className="height-96 width-96"
                            sx={{
                                backgroundColor: theme?.palette?.primary?.[50],
                            }}
                        >
                            <ChatBubbleOutline color="primary" />
                        </Avatar>
                    </Box>
                }
                type="primary"
                actions={actions}
                onClose={props.handleCloseModal}
            >
                <CustomTransComponent
                    translationKey={'bluiRegistration:HELP_SCREEN_CONTENT'}
                    components={[
                        <Typography key={0} variant="subtitle1" className={classes.typographyTitle} />,
                        <Typography key={1} variant="body1" mb={5}>
                            <Box key={0} component={'span'} color={'#268FCA'} />,
                        </Typography>,
                    ]}
                    skip={!authState?.isAuthenticated}
                    replace={{
                        email: realmSettings?.data?.contactEmail || 'BLSupport@eaton.com',
                        number: realmSettings?.data?.contactPhoneNumber || '1-877-ETN-CARE',
                    }}
                />
            </CustomModal>
        </>
    );
};
