import React from 'react';
import { Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { EmptyState, ListItemTag } from '@brightlayer-ui/react-components';
import { WeatherWidget } from 'pages/EditWidget/ChartWidgets/WeatherWidget';
import CustomIcon from 'components/CustomIcon';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import { LocationOff } from '@mui/icons-material';
import { useTypedSelector } from '@fiji/common';
import { useNavigate } from 'react-router-dom';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { UserProfile } from '@fiji/common/src/types';
import { HeaderAction } from './HeaderAction';

const getIconDetails = (condition: string): any => {
    switch (condition) {
        case 'sunny':
        case 'clear':
            return {
                web: {
                    name: 'WbSunny',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'WbSunny',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#F0AA1F' },
                },
            };
        case 'cloudy':
            return {
                web: {
                    name: 'Cloud',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'Cloud',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#D5D8DA' },
                },
            };
        case 'rain':
        case 'drizzle':
            return {
                web: {
                    name: 'Grain',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'Grain',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#7ABADE' },
                },
            };
        case 'thunder':
        case 'snow_thunder':
        case 'rain_thunder':
            return {
                web: {
                    name: 'Thunderstorm',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'Thunderstorm',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#D5D8DA' },
                },
            };
        case 'snow':
        case 'ice_pallets':
        case 'sleet':
            return {
                web: {
                    name: 'AcUnit',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'AcUnit',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#7ABADE' },
                },
            };
        case 'fog':
            return {
                web: {
                    name: 'BlurOn',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'BlurOn',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#D5D8DA' },
                },
            };
        default:
            return {
                web: {
                    name: 'WbSunny',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'WbSunny',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#F0AA1F' },
                },
            };
    }
};

const Component = (props: any): JSX.Element => {
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const selectedNode = useTypedSelector((state) => state.common.selectedNode);

    const { data: profileDetails } = useGetUserProfileQuery({}) as ApiEndpointType<ApiResponseType<UserProfile>>;

    const getLocationBasedTime = (timestamp: string, timeZone: string): string => {
        const date = new Date(timestamp);
        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const timeZoneDate = new Date(date.toLocaleString('en-US', { timeZone }));
        const offsetMs = timeZoneDate.getTime() - utcDate.getTime();
        const offsetMinutes = offsetMs / 60000; // milliseconds to minutes
        const offsetHours = Math.floor(offsetMinutes / 60);
        const remainingMinutes = offsetMinutes % 60;
        const sign = offsetHours >= 0 ? '+' : '-';
        const offsetString = `${sign}${String(Math.abs(offsetHours)).padStart(2, '0')}:${String(
            Math.abs(remainingMinutes)
        ).padStart(2, '0')}`;
        let hours = timeZoneDate.getHours();
        const period = hours < 12 ? 'AM' : 'PM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutes = timeZoneDate.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes} ${period} (UTC${offsetString})`;
    };

    const getCurrentWeatherIcon = (condition: any): JSX.Element => {
        const iconProps = getIconDetails(condition);
        return (
            <CustomIcon
                iconName={iconProps?.web?.name}
                family={iconProps?.web?.family}
                iconProps={iconProps?.additionalProps}
            />
        );
    };

    const locationDisableHandler = (): boolean => {
        if (props?.mode !== 'view' || Object.keys(props?.widgetData.assets || {})?.length === 0) {
            return true;
        }
        return false;
    };

    const handleGroupNavigation = (): void => {
        if (selectedNode?.type === 'GROUP') {
            navigate(`/${currentRealmName}/groups/edit/${selectedNode?.id}`);
        } else {
            navigate(`/${currentRealmName}/groups/edit/${props?.widgetData.assets?.id}`);
        }
    };

    return (
        <Card sx={{ height: '100%', boxShadow: props?.mode === 'viewAll' ? 'none' : '' }}>
            <>
                {props?.widgetData?.displayTitleBar && props?.mode !== 'viewAll' && (
                    <WidgetHeader
                        mode={props?.mode}
                        widgetData={props?.widgetData}
                        headerAction={
                            <HeaderAction
                                mode={props?.mode}
                                widgetData={props?.widgetData}
                                widgetFilter={props?.widgetFilter}
                                fetchWeatherData={props?.fetchWeatherData}
                            />
                        }
                    />
                )}
            </>
            {props?.mode !== 'edit' && props?.mode !== 'viewAll' && <Divider />}
            <CardContent className="padding-0">
                {Object.keys(props?.widgetData?.secondary || {})?.length ? (
                    <Stack py={2} px={3}>
                        <Stack direction={'row'} spacing={2}>
                            <Typography fontSize={'34px'} fontWeight={'600'}>
                                {props?.widgetData?.secondary?.current?.temp ?? '--'}
                                <span className="fw-300">{`º${
                                    profileDetails?.data?.temperatureUnit === 'F°(Fahrenheit)' ? 'F' : 'C'
                                }`}</span>
                            </Typography>
                            <Stack pt={1}>
                                {getCurrentWeatherIcon(props?.widgetData?.secondary?.current?.condition)}
                                {/* <WbCloudy className="gray-200 height-16 width-16" /> */}
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Typography variant="body1" fontWeight={'600'} fontSize={'16px'}>
                                        {props?.widgetData?.secondary?.locationName ?? '--'}
                                    </Typography>
                                    <ListItemTag
                                        label={
                                            props?.widgetData?.secondary?.current?.timestamp &&
                                            props?.widgetData?.secondary?.timeZone
                                                ? getLocationBasedTime(
                                                      props?.widgetData?.secondary?.current?.timestamp,
                                                      props?.widgetData?.secondary?.timeZone
                                                  )
                                                : '--'
                                        }
                                        fontSize={'10px'}
                                        fontWeight={'700'}
                                        backgroundColor="#E0EEF8"
                                        fontColor="#0088F2"
                                        letterSpacing={1}
                                        textTransform={'uppercase'}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <WeatherWidget
                            data={props?.widgetData?.secondary}
                            selectedTimePeriod={props?.widgetData?.selectedTimePeriod}
                            mode={props?.mode}
                            temperaturePreference={profileDetails?.data?.temperatureUnit}
                        />
                    </Stack>
                ) : (
                    <EmptyState
                        icon={<LocationOff fontSize={'inherit'} />}
                        title={'No location found'}
                        description={'In order to show weather forecast the group needs a defined location.'}
                        actions={
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                disabled={locationDisableHandler()}
                                onClick={handleGroupNavigation}
                            >
                                Location Preferences
                            </Button>
                        }
                    />
                )}
            </CardContent>
            {props?.widgetData?.displayViewBar && props?.mode !== 'viewAll' && (
                <WidgetFooter
                    mode={props?.mode}
                    widgetData={props?.widgetData}
                    ids={[]}
                    disabled={!Object.keys(props?.widgetData?.secondary || {})?.length}
                />
            )}
        </Card>
    );
};

export default Component;
