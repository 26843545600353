import React from 'react';
import { Divider, Paper, Stack } from '@mui/material';
import EnergyFlowWidget from 'pages/EditWidget/EnergyFlowWidget';
import EnergyFlow from 'pages/EditWidget/EnergyFlow';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';

const Component = (props: any): JSX.Element => (
    <Paper sx={{ height: '100%' }}>
        {props?.widgetData?.isTitleDisplayed && <WidgetHeader mode={props?.mode} widgetData={props?.widgetData} />}
        {props?.mode !== 'edit' && <Divider />}
        <Stack sx={{ height: 'calc(100% - 91px)', overflow: 'auto' }} data-cy="energy-flow-widget-stack">
            {props?.widgetData?.secondary?.assetNode && Object.keys(props?.widgetData?.secondary?.assetNode)?.length ? (
                <EnergyFlowWidget
                    {...props}
                    assetsCount={props?.widgetData?.assetsCount}
                    isAssetsTypeDisplayed={props?.widgetData?.isAssetsTypeDisplayed}
                />
            ) : (
                <EnergyFlow />
            )}
        </Stack>
        {props?.widgetData?.isViewAllDisplayed && (
            <WidgetFooter
                mode={props?.mode}
                widgetData={props?.widgetData}
                ids={[]}
                disabled={!Object.keys(props?.widgetData?.secondary || {})?.length}
            />
        )}
    </Paper>
);

export default Component;
