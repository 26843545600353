import React from 'react';
import {
    Check,
    EnergySavingsLeaf,
    ErrorOutline,
    Savings,
    TrendingDown,
    TrendingFlat,
    TrendingUp,
} from '@mui/icons-material';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { ChannelValue } from '@brightlayer-ui/react-components';
import { WidgetHeader } from 'pages/WidgetManagement/common';
import WidgetNotConfigured from 'pages/EditWidget/WIdgetNotConfigured';
import { HeaderAction } from '../aggregated_trends/HeaderAction';

const currencySymbols = {
    DOLLAR_SYMBOL: '$',
    EURO_SYMBOL: '€',
    SWISS_FRANC_SYMBOL: 'CHF',
};

const Component = (props: any): JSX.Element => {
    const renderIcon = (trend: string): JSX.Element => {
        switch (trend) {
            case 'UP_RED':
                return <TrendingUp sx={{ color: '#F47721' }} fontSize="large" />;
            case 'DOWN_GREEN':
                return <TrendingDown sx={{ color: '#39B620' }} fontSize="large" />;
            default:
                return <TrendingFlat color="primary" fontSize="large" />;
        }
    };

    const renderChecks = (trend: string): JSX.Element => {
        if (trend === 'UP_RED') {
            return <ErrorOutline color="error" />;
        }
        return <Check color="success" />;
    };

    const getCurrencySymbol = (currency: string): string => {
        switch (currency.toLowerCase()) {
            case 'dollar':
                return currencySymbols.DOLLAR_SYMBOL;
            case 'euro':
                return currencySymbols.EURO_SYMBOL;
            case 'swiss franc':
                return currencySymbols.SWISS_FRANC_SYMBOL;
            default:
                return currency;
        }
    };

    const getAbsentDataTitle = (): string | undefined => {
        if (props?.widgetData?.multiDevice && props?.widgetData?.assets?.length === 0) {
            return 'Widget Not Configured';
        }

        const hasConsumptionData =
            props?.widgetData?.secondary?.consumption &&
            Object.keys(props?.widgetData?.secondary?.consumption).length > 0;

        if ((!hasConsumptionData && !props?.widgetData?.multiDevice) || props?.widgetData?.assets?.length) {
            return 'No Data Found';
        }

        return undefined;
    };

    return (
        <Card sx={{ height: '100%' }}>
            {props?.widgetData?.viewTitleBar && (
                <>
                    <WidgetHeader
                        mode={props?.mode}
                        widgetData={props?.widgetData}
                        widgetFilter={props?.widgetFilter}
                        {...((props?.widgetData?.viewTimePeriod ||
                            props?.widgetData?.widgetTypeId === 'aggregated_trends') && {
                            headerAction: (
                                <HeaderAction
                                    mode={props?.mode}
                                    widgetData={props?.widgetData}
                                    widgetFilter={props?.widgetFilter}
                                    downloadCSVHandler={props?.downloadCSVHandler}
                                />
                            ),
                        })}
                    />
                    {props?.mode !== 'edit' && <Divider />}
                </>
            )}
            <CardContent className="padding-0">
                {props?.widgetData?.secondary?.consumption &&
                Object?.keys(props?.widgetData?.secondary?.consumption)?.length ? (
                    <Stack
                        pt={2}
                        px={'30px'}
                        direction={'row'}
                        justifyContent={'space-around'}
                        className="flex-100 height-160"
                    >
                        <Stack>
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <EnergySavingsLeaf color="primary" />
                                <Typography variant="body1" pl={1}>
                                    Consumption
                                </Typography>
                            </Stack>

                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    {renderIcon(props?.widgetData?.secondary?.consumption?.trend)}
                                    <ChannelValue
                                        value={parseFloat(props?.widgetData?.secondary?.consumption?.value).toFixed(2)}
                                        units={props?.widgetData?.secondary?.consumption?.unit}
                                        pl={1}
                                        fontSize={34}
                                    />
                                </Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    {renderChecks(props?.widgetData?.secondary?.consumption?.trend)}
                                    <Typography variant="body1" pl={1}>
                                        {props?.widgetData?.secondary?.consumption?.trend === 'UP_RED'
                                            ? 'At risk'
                                            : 'On Track'}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <Savings color="primary" />
                                <Typography variant="body1" pl={1}>
                                    Cost
                                </Typography>
                            </Stack>
                            <Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    {renderIcon(props?.widgetData?.secondary?.cost?.trend)}
                                    <ChannelValue
                                        value={parseFloat(props?.widgetData?.secondary?.cost?.value).toFixed(2)}
                                        units={getCurrencySymbol(props?.widgetData?.secondary?.cost?.unit)}
                                        pl={1}
                                        fontSize={34}
                                    />
                                </Stack>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    {renderChecks(props?.widgetData?.secondary?.cost?.trend)}
                                    <Typography variant="body1" pl={1}>
                                        {props?.widgetData?.secondary?.cost?.trend === 'UP_RED'
                                            ? 'At risk'
                                            : 'On Track'}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <WidgetNotConfigured title={getAbsentDataTitle()} />
                )}
            </CardContent>
        </Card>
    );
};

export default Component;
