import React, { useState } from 'react';

import { Box, FormControl, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';

export const HeaderAction = ({
    mode,
    widgetData,
    widgetFilter,
    fetchWeatherData,
}: {
    mode: string | undefined;
    widgetData: any;
    widgetFilter?: any;
    fetchWeatherData?: any;
}): JSX.Element => {
    const [lastUpdatedTimePeriod, setLastUpdateTimePeriod] = useState<any>(0);
    const [timePeriod, setTimePeriod] = useState(widgetData?.selectedTimePeriod);
    const [initialTime, setInitialTime] = useState<string>(new Date().toISOString());

    // Function to update last updated time
    const updateLastUpdatedTime = (time: string): void => {
        setLastUpdateTimePeriod(formatDistanceToNow(new Date(time), { addSuffix: true }));
    };

    React.useEffect(() => {
        if (widgetData?.widgetTypeId === 'weather') {
            updateLastUpdatedTime(initialTime);

            const intervalId = setInterval(() => {
                updateLastUpdatedTime(initialTime);
            }, 60000);

            return (): void => clearInterval(intervalId);
        }
    }, [widgetData, initialTime]);

    const handleRefresh = (id: any): void => {
        fetchWeatherData(id);
        const newInitialTime = new Date().toISOString();
        setInitialTime(newInitialTime);
        updateLastUpdatedTime(newInitialTime);

        clearInterval((window as any).lastUpdatedInterval);
        (window as any).lastUpdatedInterval = setInterval(() => {
            updateLastUpdatedTime(newInitialTime);
        }, 60000);
    };

    const handleDisableForWeatherWidget = (): boolean => {
        if (widgetData?.widgetTypeId === 'weather') {
            if (Object.keys(widgetData?.secondary || {})?.length) {
                return false;
            }
        }
        return true;
    };

    return (
        <Box display={'flex'} alignItems={'center'} position={'absolute'} right={0} sx={{ paddingRight: '16px' }}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                {widgetData?.displayLastUpdate && (
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Typography
                            variant="body2"
                            fontWeight={'300'}
                            className={mode !== 'view' ? 'text-disabled' : 'black-500'}
                        >
                            Last Updated:
                            <span className={`fw-400 ${mode !== 'view' ? 'text-disabled' : 'black-500'}`}>
                                {lastUpdatedTimePeriod}
                            </span>
                        </Typography>
                        <IconButton disabled={mode !== 'view'} onClick={(): void => handleRefresh(widgetData?.id)}>
                            <Refresh />
                        </IconButton>
                    </Stack>
                )}
                {widgetData?.displayForecastPeriod && (
                    <FormControl variant="standard" sx={{ minWidth: 80 }}>
                        <Select
                            variant="standard"
                            disableUnderline
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={timePeriod}
                            onChange={(event): void => {
                                widgetFilter(event, widgetData?.id);
                                setTimePeriod(event.target.value);
                            }}
                            sx={{ '& .MuiSelect-select': { paddingLeft: '12px' } }}
                            disabled={mode !== 'view' || handleDisableForWeatherWidget()}
                        >
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="week">7 Days</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </Stack>
        </Box>
    );
};
