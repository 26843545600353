import NavigateNext from '@mui/icons-material/NavigateNext';
import { CardActions, Divider, IconButton, Stack, Typography } from '@mui/material';

import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { WidgetMenu } from './WidgetMenu';

export const WidgetFooter = ({
    mode,
    ids,
    widgetData,
    disabled,
    modUrl,
    actionBtnName,
    noAction,
}: {
    mode: string | undefined;
    ids?: any;
    widgetData: any;
    disabled: boolean;
    modUrl?: any;
    actionBtnName?: ReactNode;
    noAction?: boolean;
}): JSX.Element => {
    const navigate = useNavigate();
    const formatString = (input: string): string =>
        input
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

    const footerName = (widgetTypeName: string): string => {
        if (widgetTypeName === 'energy_flow') {
            return 'Assets';
        } else if (widgetTypeName === 'timeline') {
            return 'Events';
        }
        return formatString(widgetTypeName) ?? '';
    };

    return (
        (mode === 'edit' ||
            widgetData?.viewAllButton ||
            widgetData?.displayViewBar ||
            widgetData?.isViewAllDisplayed) && (
            <CardActions
                sx={{
                    height: '48px',
                    maxWidth: mode === 'edit' ? 'calc(100% - 45px)' : '100%',
                    position: mode !== 'configured' ? 'absolute' : 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    bottom: 0,
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    ...(!noAction && {
                        cursor: mode !== 'view' ? 'auto' : 'pointer',
                        opacity: mode !== 'view' ? 0.5 : 1,
                    }),
                }}
            >
                <Divider sx={{ width: '100%', position: 'absolute', bottom: '48px' }} />
                {!noAction &&
                    (widgetData?.viewAllButton || widgetData?.displayViewBar || widgetData?.isViewAllDisplayed) && (
                        <>
                            {widgetData?.multiDevice && ids?.length > 0 ? (
                                <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    width={'100%'}
                                    {...(mode !== 'configured' && {
                                        sx: { position: 'absolute', bottom: '5px', left: '16px', width: '95%' },
                                    })}
                                >
                                    <Typography variant={'subtitle2'} color={'primary'} sx={{ cursor: 'pointer' }}>
                                        {actionBtnName ?? `View All ${footerName(widgetData?.widgetTypeId ?? '')} `}
                                    </Typography>
                                    <WidgetMenu
                                        disabled={mode !== 'view' || disabled}
                                        widgetData={widgetData}
                                        ids={ids}
                                    />
                                </Stack>
                            ) : (
                                <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    width={'100%'}
                                    {...(mode !== 'configured' && {
                                        sx: { position: 'absolute', bottom: '5px', left: '16px', width: '95%' },
                                    })}
                                    onClick={(): void => {
                                        if (widgetData?.route && mode === 'view') {
                                            navigate(
                                                `${modUrl?.url ?? widgetData?.route?.url}${
                                                    widgetData?.route?.state ? `#${widgetData?.route?.state}` : ''
                                                }`,
                                                {
                                                    state: {
                                                        selectedTab: modUrl?.state ?? widgetData?.route?.state ?? '',
                                                        widgetName: widgetData?.widgetName,
                                                        widgetId: widgetData?.id,
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                >
                                    <Typography variant={'subtitle2'} color={'primary'}>
                                        {actionBtnName ?? `View All ${footerName(widgetData?.widgetTypeId ?? '')} `}
                                    </Typography>
                                    <IconButton aria-label="settings" disabled={mode !== 'view' || disabled}>
                                        <NavigateNext fontSize="medium" />
                                    </IconButton>
                                </Stack>
                            )}
                        </>
                    )}
            </CardActions>
        )
    );
};
