import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { CustomTabsSkeleton, EditDashboardDetails, MappedTabs, SelectDashboardModal } from './';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CustomTabs, CustomTransComponent, Loader } from '../../components';
import Dashboard from '@mui/icons-material/Dashboard';
import Edit from '@mui/icons-material/Edit';

import {
    useGetActiveDashboardsQuery,
    useGetAllTabsQuery,
} from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';

import { mqttTopics, resetDashboard } from '@fiji/common/src/features/dashboardManagement/commonDashboardSlice';
import {
    closeMqttConnection,
    connectMQTT,
    subscribeTopic,
    unsubscribeTopic,
} from '@fiji/common/src/mqtt_connection/mqttConnection';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useIsMount, useRBAC } from 'hooks';
import { CustomMenu } from 'components/CustomMenu';
import {
    setSelectedNode,
    setSelectedNodeLoader,
    setMqttConnectionStatus,
    setMqttCredentials,
} from '@fiji/common/src/features/common/commonSlice';
import { DriveFileRenameOutline } from '@mui/icons-material';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { UserProfile } from '@fiji/common/src/types';
import { useGetMQTTCredentialsQuery } from '@fiji/common/src/features/mqtt/mqttApi';
import { decryptAES } from 'utils/helpers';

export const DashboardManagement = (): JSX.Element => {
    const isMount = useIsMount();
    const editRef: any = React.useRef(null);
    const { deviceId } = useParams();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const activeDashboardId = useTypedSelector((rootState: any) => rootState.commonDashboard.dashboardId);
    const currentRealmName = useTypedSelector((rootState: any) => rootState.common.selectedRealm);
    const navigate = useNavigate();
    const { hash } = useLocation();
    const selectModalRef = useRef<any>(null);
    const permission = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permission);
    const canUpdateSystemDashboard = hasPermission('edit-system-dashboards');
    const canUpdateUserDashboard = hasPermission('edit-user-dashboards');
    const { currentData: deviceData, isFetching: deviceDetailsFetcher }: any = useGetDeviceByIdQuery(deviceId, {
        refetchOnMountOrArgChange: true,
    });
    const { data: profileData } = useGetUserProfileQuery({}, { refetchOnMountOrArgChange: true }) as ApiEndpointType<
        ApiResponseType<UserProfile>
    >;
    const topics = useTypedSelector(mqttTopics);

    const selectedNode = useTypedSelector((state) => state['common']['selectedNode']);

    const {
        currentData: dashboards,
        isLoading,
        isFetching,
    }: any = useGetActiveDashboardsQuery(
        {
            params: {
                resourceType: 'DEVICE',
                resourceTypeId: deviceId,
            },
        },
        {
            skip: !currentOrg?.id || !deviceId,
            refetchOnMountOrArgChange: true,
        }
    );
    const { data: mqttEncryptedData }: any = useGetMQTTCredentialsQuery();
    const {
        currentData,
        isLoading: tabsLoader,
        isFetching: tabsFetcher,
    } = useGetAllTabsQuery(
        { resourceType: 'DEVICE', resourceTypeId: deviceData?.data?.deviceTypeId },
        { skip: !deviceData, refetchOnMountOrArgChange: true }
    );

    const [tabsData, setTabsData] = React.useState<any>([]);

    React.useEffect(() => {
        if (currentData?.data?.tabs?.length) {
            setTabsData(MappedTabs({ deviceId })?.filter((item: any) => currentData?.data?.tabs?.includes(item.id)));
        }
    }, [currentData]);

    React.useEffect(() => {
        if (tabsData?.length && (!selectedTab || selectedTab === '')) {
            setSelectedTab(tabsData[0]?.id);
        }
    }, [tabsData]);

    React.useEffect(() => {
        if (mqttEncryptedData?.data && profileData) {
            const decryptedCredentials = decryptAES(mqttEncryptedData?.data, {
                userId: profileData?.data?.id,
                requestId: mqttEncryptedData?.requestId,
            });
            if (decryptedCredentials?.hostname !== window.APP_CONFIG.API_URL?.replace('https://', '')) {
                decryptedCredentials['hostname'] = window.APP_CONFIG.API_URL?.replace('https://', '');
            }

            dispatch(setMqttCredentials({ ...decryptedCredentials }));
        }
    }, [mqttEncryptedData, profileData]);

    React.useEffect(() => {
        dispatch(setSelectedNodeLoader(deviceDetailsFetcher));
    }, [deviceDetailsFetcher]);

    React.useEffect(() => {
        dispatch(setSelectedNode(deviceData?.data));
    }, [deviceData]);

    React.useEffect(
        () => () => {
            if (!isMount) {
                closeMqttConnection();
                dispatch(setMqttConnectionStatus('closed-device'));
            }
        },
        []
    );

    React.useEffect(
        () => () => {
            unsubscribeTopic(topics);
        },
        [deviceId]
    );
    React.useEffect(
        () => () => {
            dispatch(resetDashboard());
        },
        [deviceId, hash]
    );

    const mqttResponseHandler = (message: any, messageTopic: any): void => {
        const selectedNodeClone = JSON.parse(JSON.stringify(selectedNode));
        if (messageTopic?.includes('BSSRM/STATUS')) {
            selectedNodeClone['deviceStatus'] = message['deviceStatus'];
            selectedNodeClone['connectionStatus'] = message['connectionStatus'];

            dispatch(setSelectedNode(selectedNodeClone));
        }
        // Handle MQTT messages here
    };

    const mqttConnectionStatus = useTypedSelector((state) => state['common']['mqttConnectionStatus']);

    const mqttCredentials = useTypedSelector((state) => state['common']['mqttCredentials']);

    React.useEffect(() => {
        if (
            mqttCredentials &&
            Object.keys(mqttCredentials)?.length &&
            (mqttConnectionStatus === false || mqttConnectionStatus === 'closed-group')
        ) {
            dispatch(setMqttConnectionStatus('loading'));

            connectMQTT(
                () => {
                    dispatch(setMqttConnectionStatus(true));
                },
                {
                    ...mqttCredentials,
                    protocol: 'wss',
                }
            );
        }
    }, [mqttCredentials, mqttConnectionStatus]);

    React.useEffect(() => {
        if (mqttConnectionStatus === true && profileData?.data && deviceData?.data && selectedNode?.id === deviceId) {
            subscribeTopic(
                `BSSRM/STATUS/${profileData?.data?.currentRealmId}/${currentOrg?.id}/${deviceData?.data?.groupId}/${deviceId}`,
                mqttResponseHandler
            );
        }
    }, [mqttConnectionStatus, profileData, deviceData, selectedNode]);

    const getActionsBtn = (): any => {
        const menuGroups = [];
        if (canUpdateSystemDashboard || canUpdateUserDashboard) {
            menuGroups.push(
                {
                    icon: <DriveFileRenameOutline />,
                    title: <CustomTransComponent translationKey={'COMMON:EDIT_DETAILS'} />,
                    onClick: (): void => editRef?.current?.handleModalAction?.(true, dashboards?.data),
                },
                {
                    title: <CustomTransComponent translationKey={'DASHBOARD_MANAGEMENT:SELECT_DASHBOARD'} />,
                    icon: <Dashboard />,
                    onClick: (): void => {
                        selectModalRef?.current?.handleModalAction(true, { deviceId });
                    },
                },
                {
                    title: <CustomTransComponent translationKey={'DASHBOARD_MANAGEMENT:EDIT_DASHBOARD'} />,
                    icon: <Edit />,
                    onClick: (): void => {
                        navigate(`/${currentRealmName}/editDashboard/${deviceId}/${activeDashboardId}`, {
                            state: {
                                assignType: dashboards?.data?.assignType,
                                assignId: deviceId ?? dashboards?.data?.assignTypeIds[0],
                            },
                        });
                    },
                }
            );
        }
        return {
            id: 'profile-menu',
            menuTitle: '',
            menuSubtitle: '',
            menuGroups,
        };
    };

    const [selectedTab, setSelectedTab] = React.useState();

    const onTabChange = (activeTab: string): void => {
        setSelectedTab(tabsData?.find((tab: any) => tab.id === activeTab)?.id ?? '');
    };

    return (
        <>
            <span className="position-relative">
                <Box sx={{ backgroundColor: '#f7f8f8', minHeight: '100vh', height: '100%' }}>
                    <CustomTabs
                        tabsData={tabsFetcher || tabsLoader || deviceDetailsFetcher ? CustomTabsSkeleton() : tabsData}
                        actions={
                            selectedTab === 'summary' &&
                            activeDashboardId &&
                            (canUpdateSystemDashboard || canUpdateUserDashboard) && (
                                <CustomMenu menuList={getActionsBtn()} />
                            )
                        }
                        variableHeader
                        showExtraTabsInMenu
                        initialTab={tabsData?.[0]?.id}
                        onTabChange={onTabChange}
                    />
                </Box>

                {(canUpdateSystemDashboard || canUpdateUserDashboard) && <SelectDashboardModal ref={selectModalRef} />}

                {isLoading && isFetching && <Loader size={60} />}
            </span>
            <EditDashboardDetails ref={editRef} />
        </>
    );
};
