import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { Header } from '@fiji/common/src/types';
import { Add, ChevronRight, Delete, Schedule } from '@mui/icons-material';
import { IconButton, Paper, Stack, Switch, Typography } from '@mui/material';
import { CustomMenu, CustomModal, CustomTable } from 'components';
import { ModalActions } from 'components/CustomModal';
import moment from 'moment';
import React from 'react';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { DisableScheduleModal } from './DisableScheduleModal';
import { useDeleteScheduleMutation, useGetAllSchedulesQuery } from '@fiji/common/src/features/schedule/scheduleApi';
import { useConfirm } from '@fiji/common/src/hooks';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { ScheduleDetails } from './ScheduleDetails';
import { useDebounce, useIsMount, useRBAC } from 'hooks';
import { useFilteredValues } from 'hooks/useFilteredValues';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';

export const ListView = ({ searchKey }: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);

    const disableModalRef = React.useRef<any>(null);

    const modalId = React.useId();
    const modalRef = React.useRef<any>(null);
    const detailsModalRef = React.useRef<any>(null);
    const [payload, setPayload] = React.useState<any>({ page: 0, size: 10, filters: {} });
    const selectedNode = useTypedSelector((state) => state.common.selectedNode);
    const isMount = useIsMount();

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);

    const canEditSchedule = hasPermission('edit-schedules');
    const canCreateSchedule = hasPermission('create-schedules');
    const canDeleteSchedule = hasPermission('delete-schedules');
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const [deleteSchedule, { isLoading: scheduleDeleting }] = useDeleteScheduleMutation();

    const handleDeleteSchedule = async (): Promise<void> => {
        const { error }: any = await deleteSchedule({ scheduleIds: selectedIds });
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: 'Schedule(s) deleted successfully.' }));
            onCancel(true);
        }
    };

    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(handleDeleteSchedule);

    const [selectedIds, setSelectedIds] = React.useState<any>();

    const tableRef = React.useRef<any>(null);

    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters ?? {})) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filters, ...(isMount ? payload.filters : {}) },
            }));
        }
        if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    React.useEffect(() => {
        if (!isMount) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    const getDeviceNameCell = (cell: any, key: string): JSX.Element => {
        if (cell[key]?.length > 2) {
            return <>{`${cell[key][0]?.name},${cell[key]?.[1]?.name} and ${cell[key]?.length - 2} others`}</>;
        }
        return cell[key]?.map((item: any) => item?.name ?? '-');
    };

    const handlePagination = (currentPage: number, pageSize: number): void => {
        setPayload((prev: any) => ({
            ...prev,
            page: currentPage,
            size: pageSize,
        }));
    };

    const getRequiredTableFilters = (filterPayload: any): any => {
        if (selectedNode?.wrapperDeviceType === 'Gateway') {
            return { ...filterPayload, gatewayId: selectedNode?.id };
        }
        if (!selectedNode?.wrapperDeviceType) {
            return { ...filterPayload, groupId: selectedNode?.id ?? currentOrg?.id };
        }
        return { ...filterPayload, deviceId: [selectedNode?.id] };
    };

    // const getExceptionListCell = (cell: any, key: string): JSX.Element => {
    //     if (cell[key]?.length > 2) {
    //         return <>{`${cell[key][0]?.},${cell[key]?.[1]?.name} and ${cell[key]?.length - 2} others`}</>;
    //     }
    //     return cell[key]?.map((item: any) => item?.name?? '-');
    // };

    const DeleteActions: ModalActions[] = [
        {
            key: 'cancel',
            label: 'Cancel',
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel(true);
            },
        },
        {
            key: 'deleteSchedule',
            label: 'Delete Schedule',
            variant: 'contained',
            color: 'error',
            disabled: scheduleDeleting,
            className: 'error-btn',
            handleClick: onConfirm,
        },
    ];

    const ActionCell = (data: any): JSX.Element => (
        <IconButton onClick={(): void => detailsModalRef?.current?.handleScheduleDetails(data)}>
            <ChevronRight />
        </IconButton>
    );

    const EnabledCell = (data: any): JSX.Element => (
        <Switch
            disabled={!canEditSchedule}
            checked={data?.enabled ?? false}
            onChange={(): void => disableModalRef?.current?.handleDisableModal(data)}
        />
    );

    const getMenuItems = (): any => {
        const menuItems: any = [];
        if (canCreateSchedule) {
            menuItems?.push({
                title: 'Add',
                icon: <Add />,
                onClick: (): void => {},
            });
        }
        if (canDeleteSchedule) {
            menuItems?.push({
                title: 'Delete',
                className: !selectedIds?.length ? 'disabled' : '',
                icon: <Delete />,
                onClick: (): void => {
                    onClick();
                },
            });
        }

        return menuItems;
    };
    const tableRows: Header[] = [
        {
            header: '',
            width: '5%',
            isSelectable: true,
        },
        {
            header: 'Enabled',
            width: '10%',
            accessor: 'enabled',
            cell: EnabledCell,
        },
        {
            header: 'Name',
            accessor: 'name',
            width: '15%',
            isFilterable: true,
            isDebounce: true,
            isSortable: true,
        },
        {
            header: 'Device',
            width: '15%',
            accessor: 'deviceName',
            cell: (data) => getDeviceNameCell(data, 'deviceIds'),
        },
        {
            header: 'Action',
            width: '15%',
            accessor: 'action',
            cell: (data) => getDeviceNameCell(data, 'actions'),
        },
        {
            header: 'Next Schedule',
            width: '15%',
            accessor: 'nextSchedule',
            cell: (data) => (
                <>
                    {data?.nextRun
                        ? `${moment(data?.nextRun).format('YYYY/MM/DD')} ${moment(data?.nextRun)?.format('hh:mm A')}`
                        : '-'}
                </>
            ),
        },
        {
            header: 'Last Run',
            width: '15%',
            accessor: 'lastRun',
            cell: (data) => (
                <>
                    {data?.lastRun
                        ? `${moment(data?.lastRun).format('YYYY/MM/DD')} ${moment(data?.lastRun)?.format('hh:mm A')}`
                        : '-'}
                </>
            ),
        },
        {
            header: 'Frequency',
            width: '10%',
            accessor: 'frequency',
        },
        {
            header: 'Exception',
            width: '10%',
            cell: (data: any) => (
                <>{data?.excludeHolidays?.length ? (data?.exceptionHolidayCountry ? 'Holiday' : 'Manual') : '-'}</>
            ),
        },
        {
            header: '',
            width: '5%',
            extraOptions: (): JSX.Element => <CustomMenu menuList={{ menuGroups: getMenuItems() }} />,

            accessor: 'actions',
            cell: ActionCell,
        },
    ];

    const [tableFilters] = useFilteredValues({ allFilters: tableRows, triggeredFilters: payload?.filters ?? {} });

    const {
        data: currentData,
        isLoading,
        isFetching,
    } = useGetAllSchedulesQuery(
        { ...payload, filters: getRequiredTableFilters(tableFilters) },
        { refetchOnMountOrArgChange: true }
    );

    return (
        <Stack mt={2}>
            <Paper>
                <CustomTable
                    ref={tableRef}
                    data={currentData?.data?.records}
                    headers={tableRows}
                    handleCheckboxSelect={(ids: any) => setSelectedIds(ids)}
                    isPagination
                    handlePageChange={handlePagination}
                    isLoading={isLoading || isFetching}
                    keyToTraverse="id"
                    noDataFoundIcon={<Schedule fontSize="inherit" />}
                    noDataFoundTitle="No Scheduled Actions"
                    noDataFoundButtonText="Choose “Add Schedule” to Schedule actions."
                    total={currentData?.data?.total ?? 10}
                    handleFilterChange={handleFilterChange}
                />
            </Paper>

            {/* Delete Schedule Action popup */}
            <CustomModal
                key={modalId}
                ref={modalRef}
                isOpen={isVisible}
                isLoading={false}
                type="primary"
                actions={DeleteActions}
                header="Delete Schedule(s) Action?"
                noDividers
                actionsDivider
            >
                <Stack>
                    <Typography variant="body1">
                        The schedule(s) will be permanently deleted. This cannot be undone.{' '}
                    </Typography>
                </Stack>
            </CustomModal>

            <DisableScheduleModal key="da4f2gc" ref={disableModalRef} />
            <ScheduleDetails ref={detailsModalRef} />
        </Stack>
    );
};
