import React, { useState } from 'react';

import { Box, FormControl, IconButton, MenuItem, Select } from '@mui/material';
import { GetApp } from '@mui/icons-material';

export const HeaderAction = ({
    mode,
    widgetData,
    widgetFilter,
    downloadCSVHandler,
}: {
    mode: string | undefined;
    widgetData: any;
    widgetFilter?: any;
    downloadCSVHandler?: any;
}): JSX.Element => {
    const [timePeriod, setTimePeriod] = useState(widgetData?.selectedTimePeriod);

    return widgetData?.viewTimePeriod || widgetData?.widgetTypeId === 'aggregated_trends' ? (
        <Box display={'flex'} alignItems={'center'} position={'absolute'} right={0} sx={{ paddingRight: '16px' }}>
            <FormControl variant="standard" sx={{ minWidth: 80 }}>
                <Select
                    variant="standard"
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timePeriod}
                    onChange={(event): void => {
                        widgetFilter(event, widgetData?.id);
                        setTimePeriod(event.target.value);
                    }}
                    sx={{ '& .MuiSelect-select': { paddingLeft: '12px' } }}
                    disabled={mode !== 'view'}
                >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                    <MenuItem value="year">Year</MenuItem>
                </Select>
            </FormControl>
            {widgetData?.widgetTypeId === 'aggregated_trends' && (
                <IconButton
                    onClick={(): void => {
                        downloadCSVHandler?.(widgetData?.id);
                    }}
                    disabled={mode !== 'view'}
                >
                    <GetApp />
                </IconButton>
            )}
        </Box>
    ) : (
        <></>
    );
};
