import React from 'react';

import {
    configLayout,
    setWidgetList,
    setApiResponse,
    setWidgetDetailsLoader,
    setDashboardWidgetIds,
} from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { api } from '@fiji/common/src/app/api/baseApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetWidgetDetails } from '@fiji/common/src/hooks';
import { customEndpointSelector, isWidgetApplicable, isWidgetConfigured } from '@fiji/common/src/utils/helpers';
import { useLocation, useParams } from 'react-router-dom';
import { useGetDashboardByIdQuery } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import {
    useGetDeviceByIdQuery,
    useGetDeviceByProfileQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useLazyGetDashboardWidgetByIdQuery as useLazyGetWidgetByIdQuery } from '@fiji/common/src/features/widgetManagement/widgetApi';

type ReturnProps = {
    isLoading: boolean;
    widgetConfigHandler: any;
};

export const useConfigureWidgets = (skip?: boolean): ReturnProps => {
    const dispatch = useAppDispatch();

    const { state } = useLocation();
    const { dashboardId, assignId } = useParams();

    const currentOrg = useTypedSelector(selectedOrg);
    const widgets = useTypedSelector((rootState) => rootState.dashboard.widgetList);
    const widgetIds = useTypedSelector((rootstate) => rootstate.dashboard.dashboardWidgetIds);
    const removedWidgets = useTypedSelector((rootstate) => rootstate.dashboard.removedWidgets);
    const loadingState = useTypedSelector((rootState) => rootState.dashboard.loaders);
    const apiResponses = useTypedSelector((rootState) => rootState.dashboard.apiResponse);

    const [getWidgetConfig, { isFetching, isLoading }] = useLazyGetWidgetByIdQuery();

    const {
        currentData: dashboard,
        isLoading: dashboardLoading,
        isFetching: dashboardFetching,
    }: any = useGetDashboardByIdQuery(dashboardId, {
        skip: !dashboardId || !currentOrg.id,
        refetchOnMountOrArgChange: true,
    });

    const { data: deviceDetails } = useGetDeviceByProfileQuery(dashboard?.data?.assignTypeIds?.[0], {
        skip:
            state?.assignType !== 'SOURCE' ||
            !dashboard ||
            !dashboard?.data?.assignTypeIds?.[0] ||
            assignId !== dashboard?.data?.assignTypeIds?.[0],
        refetchOnMountOrArgChange: true,
    });

    const { currentData: deviceData }: any = useGetDeviceByIdQuery(assignId ?? deviceDetails?.data?.id, {
        skip: state['assignType'] === 'GROUP' || !assignId,
    });

    const { payload, response: widgetResponse } = useGetWidgetDetails({
        selectorType: 'dashboard',
        dataGetter: {
            ...(state?.assignType === 'GROUP'
                ? { groupId: assignId }
                : { deviceId: deviceDetails?.data?.id ?? state?.assignId }),
        },
    });

    const widgetConfigHandler = (widgetId: any): void => {
        getWidgetConfig(widgetId)
            .then((res: any) => {
                dispatch(setWidgetList({ data: res?.data?.data, widgetId: widgetId }));
            })
            .catch((err: any) => {
                console.error(err);
            });
    };

    const getWidgetApplicableType = (widget: any): any => {
        if (
            state['assignType'] === 'GROUP' ||
            (widget?.widgetType?.id === 'device_list' && deviceData?.data['wrapperDeviceType'] === 'Gateway')
        ) {
            return 'GROUP';
        }
        return 'DEVICE';
    };

    React.useEffect(() => {
        if (widgetIds?.length && !skip) {
            widgetIds?.forEach((widgetId: any) => {
                const existWidgetIndex = widgets.findIndex((widget: any) => widget?.id === widgetId);
                if (existWidgetIndex === -1) {
                    widgetConfigHandler(widgetId);
                }
            });
        }
    }, [widgetIds]);

    React.useEffect(() => {
        if (dashboard?.data?.widgetIdList?.length && !skip) {
            dashboard?.data?.widgetIdList?.forEach((item: any) => {
                const existingWidgetIndex = widgetIds.findIndex((widget: any) => widget === item);
                const removedWidget = removedWidgets?.indexOf(item);

                if (existingWidgetIndex === -1 && removedWidget === -1) {
                    dispatch(setDashboardWidgetIds(item));
                }
            });
        }
    }, [dashboard?.data?.widgetIdList]);

    React.useEffect(() => {
        if (!skip) dispatch(configLayout(dashboard?.data));
    }, [widgets]);

    React.useEffect(() => {
        if (widgets?.length === widgetIds?.length && !skip) {
            getValues();
        }
    }, [widgets, widgetIds]);

    function setWidgetValue(widget: any, endpoint: any): Promise<any> {
        const selectedWidgetConfig = widgets?.find((prevWidget: any) => prevWidget?.id === widget?.id)?.config;
        const key = `${widget?.widgetType?.id}${
            endpoint?.endpoint === 'getDeviceAlertCount' || endpoint?.endpoint === 'getAvailableCommands'
                ? `-${endpoint?.endpoint}`
                : ''
        }`;

        return new Promise((resolve) => {
            dispatch(
                (api as any)?.endpoints?.[endpoint?.endpoint].initiate(
                    (payload?.[key] || payload?.['default'])?.(selectedWidgetConfig),
                    { forceRefetch: true }
                )
            ).then((response: any) => {
                resolve(response);
            });
        });
    }

    React.useEffect(() => {
        if (apiResponses?.length && !skip) {
            apiResponses.forEach(({ responses, type, widgetId }: any) => {
                if (!loadingState[widgetId]) {
                    (widgetResponse[type] || widgetResponse['default'])?.(responses, widgetId);
                }
            });
        }
    }, [apiResponses, widgets]);

    function getValues(): void {
        widgets?.forEach((widget: any) => {
            if (
                widget?.widgetType?.id === 'virtual_loads' ||
                widget?.widgetType?.internalCdnJsLink ||
                !isWidgetConfigured(widget) ||
                !isWidgetApplicable(widget, getWidgetApplicableType(widget))
            ) {
                dispatch(setWidgetDetailsLoader({ key: widget?.id, value: 'loaded' }));
            } else if (!loadingState[widget?.id]) {
                const promises: any = [];

                for (let i = 0; i < customEndpointSelector(widget?.widgetType?.id)?.length; i++) {
                    promises.push(
                        setWidgetValue(widget, customEndpointSelector(widget?.widgetType?.id, widget?.config?.type)[i])
                    );
                }
                Promise.all(promises)
                    .then((responses) => {
                        dispatch(setApiResponse({ widget, responses }));
                    })
                    .catch((e) => {
                        console.error(e);
                        // Handle errors here
                    });
            }
        });
    }

    return {
        isLoading: dashboardLoading || dashboardFetching || isLoading || isFetching,
        widgetConfigHandler: widgetConfigHandler,
    };
};
