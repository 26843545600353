import React from 'react';

import { useTheme } from '@mui/material/styles';
import { CustomModal, CustomTransComponent, GroupPath } from 'components';
import { useStyles } from '../../../components/AppCollapsibleHeader';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import backgroundImage from '../../../assets/images/collapsible_app_bar.jpg';
import { useSetDashboard } from '../Common';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { ThreeLiner, AppBar } from '@brightlayer-ui/react-components';
import { CreateDashboardModal } from '../CreateDashboard';
import { Button, Stack, Toolbar, AppBar as MUIAppBar, CircularProgress, Typography } from '@mui/material';
import { ModalActions } from 'components/CustomModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { useGetDashboardByIdQuery } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { useParams } from 'react-router-dom';
import { setAppHeaderSubTitle, setAppHeaderTitle } from '@fiji/common/src/features/common/commonSlice';
import { useDispatch } from 'react-redux';

export const Header = (): JSX.Element => {
    const theme: any = useTheme();
    const classes = useStyles(theme);
    const currentOrg = useTypedSelector(selectedOrg);
    const { dashboardId } = useParams();
    const widgetList = useTypedSelector((rootState) => rootState.dashboard.widgetList);
    const widgetLayout = useTypedSelector((rootState) => rootState.dashboard.layout);
    const dispatch = useDispatch();

    const selectedNode = useTypedSelector((state) => state.common.selectedNode);
    const ref: any = React.useRef(null);
    const handleCloseButton = (): void => {
        redirectionHandler();
        onCancel();
    };
    const { isVisible, onClick, onCancel, onConfirm } = useConfirm(handleCloseButton);
    const {
        currentData: dashboard,
        isLoading,
        isFetching,
    }: any = useGetDashboardByIdQuery(dashboardId, {
        skip: !dashboardId || !currentOrg.id,
    });

    React.useEffect(() => {
        if (dashboard) {
            dispatch(setAppHeaderSubTitle('Edit Dashboard'));
            dispatch(setAppHeaderTitle(dashboard?.data?.name));
        }
        return (): any => {
            dispatch(setAppHeaderSubTitle(''));
            dispatch(setAppHeaderTitle(''));
        };
    }, [dashboard]);
    const modalActions: ModalActions[] = [
        {
            key: '#exit',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            variant: 'outlined',
            close: true,
            handleClick: onCancel,
        },
        {
            key: '#closeEditor',
            label: <CustomTransComponent translationKey={'WIDGETS:CLOSE_WARN_MODAL.CLOSE_EDITOR'} />,
            variant: 'contained',
            handleClick: (): void => {
                onConfirm();
            },
        },
    ];
    const { handleSubmit, redirectionHandler, handleSaveAs, updateLoader } = useSetDashboard();

    const layoutValidator = (): boolean =>
        widgetLayout?.every((layout: any) => {
            const selectedDashboard = dashboard?.data?.widgetLayout?.find((item: any) => item['i'] === layout['i']);
            return (
                selectedDashboard &&
                selectedDashboard['i'] === layout['i'] &&
                selectedDashboard['h'] === layout['h'] &&
                selectedDashboard['w'] === layout['w'] &&
                selectedDashboard['x'] === layout['x'] &&
                selectedDashboard['y'] === layout['y']
            );
        });

    return (
        <>
            <MUIAppBar position="static">
                <Toolbar className="bg-white text-black padding-5">
                    <Stack className="w-100">
                        <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} width={'100%'}>
                                <Button
                                    disabled={isLoading || isFetching}
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={() => {
                                        if (widgetList?.length !== dashboard?.data?.widgetIdList?.length) {
                                            onClick();
                                            return;
                                        }
                                        redirectionHandler();
                                    }}
                                >
                                    <CustomTransComponent
                                        size={{ width: '50px', height: '5px' }}
                                        translationKey={'COMMON:CLOSE'}
                                    />
                                </Button>
                                <Button
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={(): void => handleSaveAs(ref)}
                                >
                                    <CustomTransComponent
                                        size={{ width: '50px', height: '5px' }}
                                        translationKey={'WIDGETS:SAVE_AS_LABEL'}
                                    />
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: theme?.palette?.primary?.main,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.main,
                                        },
                                    }}
                                    {...(updateLoader && {
                                        startIcon: <CircularProgress size={20} color="inherit" />,
                                    })}
                                    variant="contained"
                                    disabled={
                                        (dashboard?.data?.widgetIdList?.length === widgetList?.length &&
                                            layoutValidator()) ||
                                        updateLoader
                                    }
                                    onClick={handleSubmit}
                                >
                                    {updateLoader ? (
                                        <CustomTransComponent
                                            size={{ width: '80px', height: '5px' }}
                                            translationKey={'COMMON:SAVING'}
                                        />
                                    ) : (
                                        <CustomTransComponent
                                            size={{ width: '50px', height: '5px' }}
                                            translationKey={'COMMON:SAVE_LABEL'}
                                        />
                                    )}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
            </MUIAppBar>

            <AppBar
                sx={{ zIndex: 1298 }}
                backgroundImage={backgroundImage}
                collapsedHeight={'56px'}
                classes={{
                    background: classes.backGroundGradient,
                }}
            >
                <ThreeLiner
                    classes={{ title: classes.title, subtitle: classes.subtitle, info: classes.info }}
                    className={classes.liner}
                    title={
                        selectedNode?.deviceName
                            ? selectedNode?.deviceName
                            : selectedNode?.name?.length
                            ? selectedNode?.name
                            : currentOrg?.name
                    }
                    {...(selectedNode?.wrapperDeviceType && {
                        subtitle: selectedNode?.deviceStatus,
                    })}
                    info={
                        <GroupPath
                            deviceName={selectedNode?.deviceName ?? selectedNode?.name ?? currentOrg?.name}
                            pathName={selectedNode?.groupPath ?? []}
                        />
                    }
                    animationDuration={300}
                />
            </AppBar>

            <CustomModal
                key="#closeModal"
                actions={modalActions}
                isOpen={isVisible}
                type="primary"
                actionsDivider
                header={<CustomTransComponent translationKey={'DASHBOARDS:CLOSE_WARN_MODAL.HEADER'} />}
            >
                <Typography variant="subtitle2">
                    <CustomTransComponent translationKey={'DASHBOARDS:CLOSE_WARN_MODAL.DESCRIPTION'} />
                </Typography>
            </CustomModal>
            <CreateDashboardModal
                actionButtonName={<CustomTransComponent translationKey={'DASHBOARDS:CLONE_DASHBOARD'} />}
                header={<CustomTransComponent translationKey={'WIDGETS:SAVE_AS_LABEL'} />}
                ref={ref}
            />
        </>
    );
};
